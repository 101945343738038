import useSimpleDialog from '@app/hooks/use-simple-dialog';
import { AvailableTransport, CustomTransport } from '@shared/api/types';
import {
  CategorizedPicker,
  Category,
} from '@shared/components/form/categorized-picker';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@shared/components/ui/form';
import { cn } from '@shared/lib/utils';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import CreateTransportForm from './create-ppt-forms/create-transport-form';

interface ProductTransportPickerProps {
  name: string;
  label?: string;
  availableTransport: AvailableTransport;
  index?: number;
  className?: string;
  includeErrorMessage?: boolean;
}

export default function ProductTransportPicker({
  label,
  name,
  availableTransport,
  index,
  className,
  includeErrorMessage,
}: ProductTransportPickerProps) {
  const { control, setValue, getValues } = useFormContext();
  const { showSimpleDialog, closeSimpleDialog, SimpleDialogComponent } =
    useSimpleDialog();

  type AvailableTransportType = CustomTransport;

  const categories: Category<AvailableTransportType>[] = useMemo(() => {
    const customTransportCategory: Category<CustomTransport> = {
      name: 'My transport',
      items: availableTransport.custom!,
      searchableKeys: ['name'],
      valueKey: 'uuid',
      labelKey: 'name',
      onSelect: (selectedItem: CustomTransport) => {
        setValue(`transport.${index}.name`, selectedItem.name);
        setValue(`transport.${index}.transportType`, selectedItem.uuid);
      },
    };
    return [customTransportCategory] as Category<AvailableTransportType>[];
  }, [availableTransport.custom, index, setValue]);

  return (
    <FormField
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <>
            <FormItem className={cn('grid flex-1 items-center', className)}>
              {label && <FormLabel>{label}</FormLabel>}
              <FormControl>
                <CategorizedPicker
                  categories={categories}
                  value={field.value}
                  key={categories[0].items.length}
                  error={fieldState.error?.message}
                  onCreate={showSimpleDialog}
                />
              </FormControl>
              {includeErrorMessage &&
                index === getValues('transport').length - 1 && <FormMessage />}
            </FormItem>
            {SimpleDialogComponent({
              header: 'Create Transport Step',
              content: (
                <CreateTransportForm
                  onTransportCreated={(data) => {
                    setValue(`transport.${index}.name`, data.name);
                    setValue(`transport.${index}.transportType`, data.uuid);

                    closeSimpleDialog();
                  }}
                />
              ),
            })}
          </>
        );
      }}
    />
  );
}
